<template>
  <v-container class="bg-section mb-16 mt-16" fluid>
    <div class="mx-16 text-center mt-16">
      <h2>
        Descubre todas las características
      </h2>
    </div>
    <div class="bg-cards mt-12">
      <v-row class="justify-center align-center text-center pa-8">
        <v-col :md="3" class="pa-4 mx-8 mb-10 bg-card" v-for="element in elements" :key="element">
          <v-img
            :src="require(`@/assets/icons/i-`+element.img+`.png`)"
            contain
            height="40"
            class="my-6"
          />
          <h5 class="mb-2 mx-4"> {{ element.title }} </h5>
          <h6 class="mb-6 mx-4"> {{ element.subtitle }}  </h6>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Features",

  data: () => ({
      elements : [
          {title: "Facturación", subtitle: "Podrás crear y enviar facturas desde el teléfono en cualquier momento.", img: "fact"},
        {title: "Contabilidad", subtitle: "Gestiona toda la contabilidad de tu empresa desde cualquier dispositivo.", img: "cont"},
        {title: "Digitalización", subtitle: "Lleva contigo la información de tu negocio estés donde estés.", img: "dig"},
        {title: "Conciliación Bancaria", subtitle: "Obtén información en tiempo real del estado de tu negocio.", img: "con"},
        {title: "Impuestos", subtitle: "Completa de manera automática los modelos de impuestos de tu negocio.", img: "imp"},
        {title: "Gestión documental", subtitle: "Accede a todos tus documentos ordenados desde el gestor documental.", img: "doc"},
        {title: "Inventarios", subtitle: "Optimiza la gestión de tu proyecto de la forma más sencilla, completa y automatizada.", img: "doc"},
        {title: "Compras", subtitle: "Accede a todos tus documentos ordenados desde el gestor documental.", img: "doc"},
        {title: "Portal de Proveedores", subtitle: "Accede a todos tus documentos ordenados desde el gestor documental.", img: "doc"}
      ]
  }),
};
</script>

<style scoped>
.bg-cards {
  background: #fdfdfd;
}

.bg-card {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 14px;
}
</style>

<template>
  <v-container class="pa-0" fluid>
    <Features />
  </v-container>
</template>

<script>
  import Features from '../components/sections/Features.vue'

  export default {
    name: 'All',

    components: {
      Features
    },
  }
</script>